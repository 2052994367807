import React, { useEffect, useState } from "react";
import HeaderComponent from "../components/header";
import getMobileOperatingSystem from "../Utils/getMobileOperatingSystem";
import OperatingSystemEnum from "../Enums/OperatingSystemEnum";
import AndroidDownload from "../Assets/pngs/Playstore-download.png";
import IOSDownload from "../Assets/pngs/Appstore-download.png";
import DLActionHandler from "../Utils/dlActionHandler";

import { ReactComponent as AppInstall } from "../Assets/svgs/appinstall.svg";
import Interval from "../Utils/intervals";
import { useNavigate } from "react-router-dom";

function DLhandler() {
  const queryParameters = new URLSearchParams(window.location.search);

  const navigate = useNavigate();


  const RefSourceId = queryParameters.get("RefSourceId");
  const campshareId = queryParameters.get("campshare");
  const [timer, setTimer] = useState(2)
  const DeviceType = getMobileOperatingSystem();


  useEffect(() => {

    if (campshareId && DeviceType === OperatingSystemEnum.ios) {
      setTimeout(() => {
        console.log("hola")
        window.open(`com.lookwhostalkingApp://dl?campshare=${campshareId}`);
        console.log("holaaa")
      },);
    }
    DLActionHandler(window.location.search);
  }, []);

  useEffect(() => {
    if (campshareId) {
      if (timer > 0) { 
      setTimeout(() => {
        setTimer(timer-1)
      }, 1000)
      }else {
        navigate(`/engage/${campshareId}`)
      }
    }
  }, [timer])
  



  return (
    <div>
      <HeaderComponent />
      <div
        className="flex flex-col w-full items-center justify-center md:px-24 px-2"
        style={{ height: "calc(100vh - 91px)" }}
      >
        <div className="flex flex-col items-center justify-center w-[60%] px-4 py-2 rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
          {window.location.search !== null && Boolean(campshareId) && (
            <>
              <span className="text-center mt-2 mb-2 text-sm">
              You'll be automatically redirected to campaign detail page in {timer} seconds.
          </span>
              <button
                onClick={() => {
                  window.open(`${window.location.origin}/engage/${campshareId}`);
                }}
                className="px-2.5 py-1 mt-2 rounded bg-[#f15847] text-white text-sm mb-2"
              >
                View Campaign Details
              </button>
            </>
          )}
          <div className="flex w-full items-center justify-center py-4 mt-4 mb-2">
            <AppInstall className="h-[20vh]" />
          </div>
          <span className="text-center mt-2 mb-2 text-sm">
            Please proceed with the LWT App for further steps & notifications.{" "}
          </span>
          <div className="flex items-center w-full justify-center my-4">
            {OperatingSystemEnum[DeviceType] !== OperatingSystemEnum.ios ? (
              <button
                className="outline-none border-none align-self-end mr-4"
                onClick={() => {
                  window.open(
                    `market://details?id=com.lookwhostalkingApp&referrer=${RefSourceId}`
                  );
                }}
              >
                <img
                  alt="Download LWT from App Store"
                  src={AndroidDownload}
                  height={120}
                  width={200}
                />
              </button>
            ) : (
              <></>
            )}

            {DeviceType !== OperatingSystemEnum.android ? (
              <button
                className="outline-none border-none align-self-end"
                onClick={() => {
                  window.open("itms-apps://itunes.apple.com/app/id1579637316");
                }}
              >
                <img
                  alt="Download LWT from Play Store"
                  src={IOSDownload}
                  height={120}
                  width={200}
                />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DLhandler;
