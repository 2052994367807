import axios from "axios";
import constants from "../../constants";
import ApiCallResponse from "./ApiCallResponse";
import ChannelConnectType from "../../Enums/ChannelConnectTypeEnum";

function AddCreatorChannel({data}) {
    return new Promise((resolve, reject) => {
            if (!data) {
                return reject(new ApiCallResponse({
                    status: false,
                    messageToUser: "Please Try Again",
                    isFatal: true,
                    sendReport: true,
                    errorObject: {
                        message: "NO CHANNEL DATA FOUND"
                    }
                }))
            }

            let token = localStorage.getItem("Authorization")

            let config = {
                headers: {
                  Authorization: token
                },
              };

            return axios.post(`${constants.API_URL}/Creator/channel`, data, config)
            .then(data => {
                console.log("dataaa",data);
                return resolve(true)
            })
            .catch(err => {
                console.error("dataaa",err)
            })
       
    })
}

export default AddCreatorChannel

export const AddInstaWebCreatorChannel = ({
    channelInfo, accId, page_access_token, isManual=false, connectType = ChannelConnectType.Unassigned
}) => {
    return new Promise((resolve, reject) => {
            if (!channelInfo) {
                return reject(new ApiCallResponse({
                    status: false,
                    messageToUser: "Please Try Again",
                    isFatal: true,
                    sendReport: true,
                    errorObject: {
                        message: "NO CHANNEL DATA FOUND"
                    }
                }))
            }

            let _channelInfo = {
                ...channelInfo,
                ConnectType: connectType
            }

            let token = localStorage.getItem("Authorization")

            let config = {
                headers: {
                  Authorization: token
                },
              };

            return axios.post(`${constants.API_URL}/Creator/channel`, _channelInfo, config)
            .then(data => {
                console.log("dataaa",data);
                return resolve(true)
            })
            .catch(err => {
                console.error("dataaa",err)
            })
       
    })
}