const Interval = {
    // to keep a reference to all the intervals
    intervals : new Set(),
    
    // create another interval
    make(...args) {
        const newInterval = window.setInterval(...args);
        this.intervals.add(newInterval);
        return newInterval;
    },

    // clear a single interval
    clear(id) {
        this.intervals.delete(id);
        return window.clearInterval(id);
    },

    // clear all intervals
    clearAll() {
        this.intervals.clear();
    }
};

export default Interval