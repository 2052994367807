export const GENREMETA = [
  { value: 1, label: "Fashion" },
  { value: 2, label: "Travel" },
  { value: 3, label: "Lifestyle" },
  { value: 4, label: "Technology" },
  { value: 5, label: "Sports" },
  { value: 6, label: "Entertainment" },
  { value: 7, label: "Food" },
  { value: 8, label: "Auto" },
  { value: 9, label: "Books" },
  { value: 10, label: "Art" },
  { value: 11, label: "Education" },
  { value: 12, label: "Business" },
  { value: 13, label: "Environment" },
  { value: 14, label: "Gaming" },
  { value: 15, label: "Health" },
  { value: 16, label: "Relationship" },
  { value: 17, label: "Music" },
  { value: 18, label: "Photography" },
  { value: 19, label: "Literature" },
  { value: 20, label: "Politics" },
  { value: 21, label: "Science" },
  { value: 22, label: "Finance" },
  { value: 23, label: "Interior" },
  { value: 24, label: "Wedding" },
  { value: 25, label: "Fitness" },
  { value: 26, label: "Parenting" },
  { value: 27, label: "Pets" },
  { value: 28, label: "Beauty" },
  { value: 29, label: "News & Media" },
];
