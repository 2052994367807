import OperatingSystemEnum from "../Enums/OperatingSystemEnum";

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;


    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return OperatingSystemEnum.windows_phone;
    }

    if (/android/i.test(userAgent)) {
        return OperatingSystemEnum.android;
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return OperatingSystemEnum.ios;
    }

    return OperatingSystemEnum.desktop;
}

export default getMobileOperatingSystem