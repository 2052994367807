import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/header";
import { useDispatch, useSelector } from "react-redux";
import { CountriesEnum } from "../Enums/CountriesEnum";
import billingDetailsValidateFields from "../Utils/BillingDetailsValidation";
import updateCreatorBillingDetails from "../Services/apicalls/UpdateCreatorBillingDetails";
import { getCreatorThunk } from "../API";
import cogoToast from "cogo-toast";
import Loader from "../components/Loader";

const BillingInitialValues = {
  name: "",
  address: "",
  phonenumber: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  pan: "",
  aadhar: "",
  accountholder: "",
  bankname: "",
  accountnumber: "",
  ifsc: "",
  gst: "",
  upi: "",
};


const Settings = () => {

  const creatorData = useSelector((state) => state?.creatorData?.data);
  const dispatch = useDispatch()


  const [BillingValues, setBillingValues] = useState(BillingInitialValues);
  const [ShowLoader, setShowLoader] = useState(false)

  const COUNTRIES = Object.keys(CountriesEnum).slice(Object.keys(CountriesEnum).length / 2 + 1, Object.keys(CountriesEnum).length-1)

  useEffect(() => {

    let billing = creatorData?.billing ? creatorData?.billing : {}
    console.log("bulling", billing);
    setBillingValues({
      name: billing.billingName,
      address: billing.billingAddress,
      phonenumber: billing.billingContact,
      city: billing.billingCity,
      state: billing.billingState,
      country: billing.billingCountry,
      pincode: billing.billingPincode,
      pan: billing.pan,
      aadhar: billing.uid,
      accountholder: billing.accountName,
      bankname: billing.bankName,
      accountnumber: billing.accountNumber,
      ifsc: billing.bankIFSC,
      gst: billing.gst,
      upi: billing.upi,
    })
  }, [creatorData])

  const updateBillingDetails = () => {
    setShowLoader(true)
    billingDetailsValidateFields(BillingValues)
    .then(() => updateCreatorBillingDetails(BillingValues))
    .then(() => dispatch(getCreatorThunk()))
    .then(() => cogoToast.success("Successfull updated"))
    .catch(err => {
      console.log("error", err);
      cogoToast.warn(err.message)
    })
    .finally(() => setShowLoader(false))
    
  }
  

//NOTE - input value change handler for billing details
  const changeHandler = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    return setBillingValues((prev) =>  ({ ...prev, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    updateBillingDetails()
    console.log(BillingValues);
  };

  return (
    <div>
      {
        ShowLoader ?
        <Loader />
        :
        <></>
      }
      {" "}
      <HeaderComponent />
      <div
        className="flex flex-col items-center px-8 py-8 w-full"
        style={{ background: "#f6f6f6", height: "fit-content" }}
      >
        <div className="text-lg text-center mb-8">
          Hey, here you can fill out your billing details.
        </div>
        <div className="md:pt-4 md:px-4 w-full md:w-3/4">
          <form onSubmit={handleSubmit} className="md:w-full mx-auto h-max">
            <div className="md:flex  justify-between md:mb-10 ">
              <InputLabel
                label="Name *"
                name="name"
                required={true}
                onChange={changeHandler}
                width="w-1/3"
                value={BillingValues.name}
              />

              <InputLabel
                label="Address *"
                name="address"
                onChange={changeHandler}
                width="w-1/3"
                value={BillingValues.address}
              />

              <InputLabel
                label="Contact *"
                width="w-1/3"
                onChange={changeHandler}
                name="phonenumber"
                type="text"
                value={BillingValues.phonenumber}
                required={true}
              />
            </div>

            <div className="md:flex  justify-between md:mb-10">
              <InputLabel
                onChange={changeHandler}
                label="City *"
                name="city"
                value={BillingValues.city}
                required={true}
              />
              <InputLabel
                label="State *"
                name="state"
                onChange={changeHandler}
                value={BillingValues.state}
                required={true}
              />
              <SelectLabel
                label="Country"
                onChange={changeHandler}
                name={"country"}
                value={BillingValues.country}
                required
              >
                <option hidden value=""></option>
                {COUNTRIES?.map((country, key) => {
                  return (
                    <option key={key} value={CountriesEnum[country]}>
                      {country}
                    </option>
                  );
                })}
              </SelectLabel>
            </div>

            {/* <div className="md:flex  justify-between md:mb-10">
              
            </div> */}

            <div className="md:flex  justify-between md:mb-10">
              <InputLabel
                label="Pincode *"
                name="pincode"
                value={BillingValues.pincode}
                onChange={changeHandler}
                required={true}
              />
              <InputLabel
                label="PAN *"
                name="pan"
                value={BillingValues.pan}
                style={{textTransform: 'uppercase'}}
                onChange={changeHandler}
                required={true}
              />
              <InputLabel
                label="AADHAR *"
                name="aadhar"
                value={BillingValues.aadhar}
                onChange={changeHandler}
                required={true}
              />
            </div>

            <div className="md:flex  justify-between md:mb-10">
              <InputLabel
                label="Bank Name *"
                onChange={changeHandler}
                name="bankname"
                value={BillingValues.bankname}
                required={true}
              />
              <InputLabel
                label="Account Holder Name *"
                onChange={changeHandler}
                name="accountholder"
                value={BillingValues.accountholder}
                required={true}
              />
              <InputLabel
                label="Account Number *"
                type="number"
                onChange={changeHandler}
                name="accountnumber"
                value={BillingValues.accountnumber}
                required={true}
              />
              <InputLabel
                label="IFSC Code *"
                onChange={changeHandler}
                name="ifsc"
                value={BillingValues.ifsc}
                required={true}
              />
            </div>
            <div className="md:flex justify-start md:mb-10 xl:w-2/3 w-full">
              <InputLabel
                label="GST"
                onChange={changeHandler} // check needs to be added
                name="gst"
                value={BillingValues.gst}
              />
              <InputLabel
                label="UPI *"
                onChange={changeHandler}
                name="upi"
                value={BillingValues.upi}
                required={true}
              />
            </div>

            <div className="w-full flex justify-end my-3 px-3 ">
              <button
                type="submit"
                style={{ backgroundColor: "#f15847" }}
                className=" text-white py-2 rounded block md:w-max w-full px-2"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;

const InputLabel = ({
  name,
  type,
  onChange,
  value,
  label,
  width,
  required,
  disabled,
  pattern,
}) => {
  return (
    <div
      className={`md:${
        width || "w-1/2"
      } mb-2 md:mb-0 px-3 flex flex-col w-full `}
    >
      <div className="flex">
        <Label name={name} label={label} />
      </div>

      <Input
        name={name}
        id={name}
        type={type || "text"}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        pattern={pattern}
      />
    </div>
  );
};

const SelectLabel = ({
  name,
  type,
  onChange,
  value,
  label,
  width,
  children,
  required,
}) => {
  return (
    <div
      className={`md:${
        width || "w-1/2"
      } mb-2 md:mb-0 px-3 flex flex-col w-full `}
    >
      <Label name={name} label={label} />

      <Select
        name={name}
        id={name}
        type={type || "text"}
        value={value}
        onChange={onChange}
        required={true}
      >
        {children}
      </Select>
    </div>
  );
};

const Input = ({
  name,
  type,
  onChange,
  value,
  required,
  disabled,
  pattern,
}) => {
  return (
    <input
      onChange={onChange}
      name={name}
      className="flex-1  appearance-none border border-secondary-400 rounded-lg w-full py-2 px-4 text-secondary-700 leading-tight focus:outline-none "
      id={name}
      type={type || "text"}
      value={value}
      required={required}
      disabled={disabled}
      pattern={pattern}
    />
  );
};

const Label = ({ label, name }) => {
  return (
    <label
      className="block  text-secondary-400 font-medium text-sm mb-2 md:mb-0 pr-4 whitespace-nowrap"
      htmlFor={name}
    >
      {label}
    </label>
  );
};

const Select = ({ name, onChange, value, children }) => {
  return (
    <div className="inline-block relative w-full">
      <select
        name={name}
        onChange={onChange}
        className="border bg-white   outline-none  appearance-none  border-secondary-400 rounded-lg w-full py-2 px-4 text-secondary-400  "
        value={value}
      >
        {children}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-secondary-400">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};
