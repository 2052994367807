import axios from "axios"
import constants from "../../constants"


/**
 * TODO -  Map the billingDetails param's object to the request body required scheme
 * its better in that way so that we don't have to take care of this function while
 * changing the fields
 */


/**
 * @name {updateCreatorBillingDetails}
 * @param billingDetails -> make sure to match the names here
 * 
*/

export default function updateCreatorBillingDetails(billingDetails) {

    //NOTE - Make sure all the fields here must of the same mapped correctly.
    // incorrect mapping will lead to missing data and mostly this error would be silent.
    let requestBody = {
        upi: billingDetails.upi,
        uid: billingDetails.aadhar,
        billingName: billingDetails.name,
        billingAddress: billingDetails.address,
        billingCity: billingDetails.city,
        billingState: billingDetails.state,
        billingContact: billingDetails.phonenumber,
        gst: billingDetails.gst,
        accountNumber: billingDetails.accountnumber,
        accountName: billingDetails.accountholder,
        bankName: billingDetails.bankname,
        bankIFSC: billingDetails.ifsc,
        billingCountry: billingDetails.country,
        billingPincode: billingDetails.pincode,
        pan: billingDetails.pan
    }

    let token = localStorage.getItem("Authorization")

    let config = {
        headers: {
            Authorization: token
        },
    };

    return new Promise((resolve, reject) => {
        return axios.post(`${constants.API_URL}/Creator/updateBilling`, requestBody, config)
            .then((response) => {
                return resolve({status: response.data, data: response.data});
            })
            .catch((error) => {
                console.log(error);
                return reject({status: false, message: error.message});
            })
    })

}