import axios from "axios";
import constants from "../../constants";
 
export function getContactSupport(val, msg) {
  return new Promise(function (resolve, reject) {
    const baseURL = constants.API_URL;
    var url = baseURL + "/User/contactSupport";
    var fileData = new FormData();
    fileData.append("type", val);
    fileData.append("message", msg);

    let token = localStorage.getItem("Authorization")

    let config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    if (token) {
      config.headers =  {
        Authorization: token
      }
    }

    axios
      .post(url, fileData, config)
      .then((response) => {
       console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
