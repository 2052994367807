//this file has a dependency in CreatorProfile, if any country is added in this file make necessary changes in CreatorProfile.js as well
export const CountriesEnum = {
    "unassigned": 0,
    "Afghanistan": 1,
    "Albania": 2,
    "Algeria": 3,
    "American Samoa": 4,
    "Andorra": 5,
    "Angola": 6,
    "Anguilla": 7,
    "Antarctica": 8,
    "Antigua and Barbuda": 9,
    "Argentina": 10,
    "Armenia": 11,
    "Aruba": 12,
    "Australia": 13,
    "Austria": 14,
    "Azerbaijan": 15,
    "Bahamas": 16,
    "Bahrain": 17,
    "Bangladesh": 18,
    "Barbados": 19,
    "Belarus": 20,
    "Belgium": 21,
    "Belize": 22,
    "Benin": 23,
    "Bermuda": 24,
    "Bhutan": 25,
    "Bolivia": 26,
    "Bosnia and Herzegowina": 27,
    "Botswana": 28,
    "Bouvet Island": 29,
    "Brazil": 30,
    "British Indian Ocean Territory": 31,
    "Brunei Darussalam": 32,
    "Bulgaria": 33,
    "Burkina Faso": 34,
    "Burundi": 35,
    "Cambodia": 36,
    "Cameroon": 37,
    "Canada": 38,
    "Cape Verde": 39,
    "Cayman Islands": 40,
    "Central African Republic": 41,
    "Chad": 42,
    "Chile": 43,
    "China": 44,
    "Christmas Island": 45,
    "Cocos Islands": 46,
    "Colombia": 47,
    "Comoros": 48,
    "Congo": 49,
    "Cook Islands": 50,
    "Costa Rica": 51,
    "Croatia": 52,
    "Cuba": 53,
    "Cyprus": 54,
    "Czech Republic": 55,
    "Denmark": 56,
    "Djibouti": 57,
    "Dominica": 58,
    "Dominican Republic": 59,
    "Timor Leste": 60,
    "Ecuador": 61,
    "Egypt": 62,
    "El Salvador": 63,
    "Equatorial Guinea": 64,
    "Eritrea": 65,
    "Estonia": 66,
    "Ethiopia": 67,
    "Falkland Islands": 68,
    "Faroe Islands": 69,
    "Fiji": 70,
    "Finland": 71,
    "France": 72,
    "French Guiana": 73,
    "French Polynesia": 74,
    "French Southern Territories": 75,
    "Gabon": 76,
    "Gambia": 77,
    "Georgia": 78,
    "Germany": 79,
    "Ghana": 80,
    "Gibraltar": 81,
    "Greece": 82,
    "Greenland": 83,
    "Grenada": 84,
    "Guadeloupe": 85,
    "Guam": 86,
    "Guatemala": 87,
    "Guinea": 88,
    "Guinea bissau": 89,
    "Guyana": 90,
    "Haiti": 91,
    "Heard and McDonald Islands": 92,
    "Honduras": 93,
    "Hong Kong": 94,
    "Hungary": 95,
    "Iceland": 96,
    "India": 97,
    "Indonesia": 98,
    "Iran": 99,
    "Iraq": 100,
    "Ireland": 101,
    "Israel": 102,
    "Italy": 103,
    "Jamaica": 104,
    "Japan": 105,
    "Jordan": 106,
    "Kazakhstan": 107,
    "Kenya": 108,
    "Kiribati": 109,
    "Korea": 110,
    "South Korea": 111,
    "Kuwait": 112,
    "Kyrgyzstan": 113,
    "Lao": 114,
    "Latvia": 115,
    "Lebanon": 116,
    "Lesotho": 117,
    "Liberia": 118,
    "Libya": 119,
    "Liechtenstein": 120,
    "Lithuania": 121,
    "Luxembourg": 122,
    "Macao": 123,
    "Macedonia": 124,
    "Madagascar": 125,
    "Malawi": 126,
    "Malaysia": 127,
    "Maldives": 128,
    "Mali": 129,
    "Malta": 130,
    "Marshall Islands": 131,
    "Martinique": 132,
    "Mauritania": 133,
    "Mauritius": 134,
    "Mayotte": 135,
    "Mexico": 136,
    "Micronesia": 137,
    "Moldova": 138,
    "Monaco": 139,
    "Mongolia": 140,
    "Montserrat": 141,
    "Morocco": 142,
    "Mozambique": 143,
    "Myanmar": 144,
    "Namibia": 145,
    "Nauru": 146,
    "Nepal": 147,
    "Netherlands": 148,
    "Netherlands Antilles": 149,
    "New Caledonia": 150,
    "New Zealand": 151,
    "Nicaragua": 152,
    "Niger": 153,
    "Nigeria": 154,
    "Niue": 155,
    "Norfolk Island": 156,
    "Northern Mariana Islands": 157,
    "Norway": 158,
    "Oman": 159,
    "Pakistan": 160,
    "Palau": 161,
    "Panama": 162,
    "Papua New Guinea": 163,
    "Paraguay": 164,
    "Peru": 165,
    "Philippines": 166,
    "Pitcairn": 167,
    "Poland": 168,
    "Portugal": 169,
    "Puerto Rico": 170,
    "Qatar": 171,
    "Reunion": 172,
    "Romania": 173,
    "Russian Federation": 174,
    "Rwanda": 175,
    "Saint Kitts and Nevis": 176,
    "Saint Lucia": 177,
    "Saint Vincent and the Grenadines": 178,
    "Samoa": 179,
    "San Marino": 180,
    "Sao Tome and Principe": 181,
    "Saudi Arabia": 182,
    "Senegal": 183,
    "Seychelles": 184,
    "Sierra Leone": 185,
    "Singapore": 186,
    "Slovakia": 187,
    "Slovenia": 188,
    "Solomon Islands": 189,
    "Somalia": 190,
    "South Africa": 191,
    "South Georgia and the South Sandwich Islands": 192,
    "Spain": 193,
    "Sri Lanka": 194,
    "Saint Helena": 195,
    "St Pierre and Miquelon": 196,
    "Sudan": 197,
    "Suriname": 198,
    "Svalbard and Jan Mayen Islands": 199,
    "Swaziland": 200,
    "Sweden": 201,
    "Switzerland": 202,
    "Syrian Arab Republic": 203,
    "Taiwan": 204,
    "Tajikistan": 205,
    "United Republic of Tanzania": 206,
    "Thailand": 207,
    "Togo": 208,
    "Tokelau": 209,
    "Tonga": 210,
    "Trinidad and Tobago": 211,
    "Tunisia": 212,
    "Turkey": 213,
    "Turkmenistan": 214,
    "Turks and Caicos Islands": 215,
    "Tuvalu": 216,
    "Uganda": 217,
    "Ukraine": 218,
    "United Arab Emirates": 219,
    "United Kingdom": 220,
    "United States": 221,
    "United States Minor Outlying Islands": 222,
    "Uruguay": 223,
    "Uzbekistan": 224,
    "Vanuatu": 225,
    "Vatican City": 226,
    "Venezuela": 227,
    "Vietnam": 228,
    "Virgin Islands British": 229,
    "Virgin Islands US": 230,
    "Wallis and Futuna Islands": 231,
    "Western Sahara": 232,
    "Yemen": 233,
    "Serbia": 234,
    "Zambia": 235,
    "Zimbabwe": 236,
    "Aland Islands": 237,
    "Bonaire Saint Eustatius and Saba": 238,
    "Palestine": 239,
    "Montenegro": 240,
    "Guernsey": 241,
    "Isle of Man": 242,
    "Jersey": 243,
    "Curaçao": 244,
    "Ivory Coast": 245,
    "Kosovo": 246,
    "Sint Maarten": 247,
    "Serbia and Montenegro": 248,
    "Saint Barthelemy": 249,
    "Democratic Republic of the Congo": 250,
    "Saint Martin": 251,
    "South Sudan": 252,
    0: "unassigned",
    1: "Afghanistan",
    2: "Albania",
    3: "Algeria",
    4: "American Samoa",
    5: "Andorra",
    6: "Angola",
    7: "Anguilla",
    8: "Antarctica",
    9: "Antigua and Barbuda",
    10: "Argentina",
    11: "Armenia",
    12: "Aruba",
    13: "Australia",
    14: "Austria",
    15: "Azerbaijan",
    16: "Bahamas",
    17: "Bahrain",
    18: "Bangladesh",
    19: "Barbados",
    20: "Belarus",
    21: "Belgium",
    22: "Belize",
    23: "Benin",
    24: "Bermuda",
    25: "Bhutan",
    26: "Bolivia",
    27: "Bosnia and Herzegowina",
    28: "Botswana",
    29: "Bouvet Island",
    30: "Brazil",
    31: "British Indian Ocean Territory",
    32: "Brunei Darussalam",
    33: "Bulgaria",
    34: "Burkina Faso",
    35: "Burundi",
    36: "Cambodia",
    37: "Cameroon",
    38: "Canada",
    39: "Cape Verde",
    40: "Cayman Islands",
    41: "Central African Republic",
    42: "Chad",
    43: "Chile",
    44: "China",
    45: "Christmas Island",
    46: "Cocos Islands",
    47: "Colombia",
    48: "Comoros",
    49: "Congo",
    50: "Cook Islands",
    51: "Costa Rica",
    52: "Croatia",
    53: "Cuba",
    54: "Cyprus",
    55: "Czech Republic",
    56: "Denmark",
    57: "Djibouti",
    58: "Dominica",
    59: "Dominican Republic",
    60: "Timor Leste",
    61: "Ecuador",
    62: "Egypt",
    63: "El Salvador",
    64: "Equatorial Guinea",
    65: "Eritrea",
    66: "Estonia",
    67: "Ethiopia",
    68: "Falkland Islands",
    69: "Faroe Islands",
    70: "Fiji",
    71: "Finland",
    72: "France",
    73: "French Guiana",
    74: "French Polynesia",
    75: "French Southern Territories",
    76: "Gabon",
    77: "Gambia",
    78: "Georgia",
    79: "Germany",
    80: "Ghana",
    81: "Gibraltar",
    82: "Greece",
    83: "Greenland",
    84: "Grenada",
    85: "Guadeloupe",
    86: "Guam",
    87: "Guatemala",
    88: "Guinea",
    89: "Guinea bissau",
    90: "Guyana",
    91: "Haiti",
    92: "Heard and McDonald Islands",
    93: "Honduras",
    94: "Hong Kong",
    95: "Hungary",
    96: "Iceland",
    97: "India",
    98: "Indonesia",
    99: "Iran",
    100: "Iraq",
    101: "Ireland",
    102: "Israel",
    103: "Italy",
    104: "Jamaica",
    105: "Japan",
    106: "Jordan",
    107: "Kazakhstan",
    108: "Kenya",
    109: "Kiribati",
    110: "Korea",
    111: "South Korea",
    112: "Kuwait",
    113: "Kyrgyzstan",
    114: "Lao",
    115: "Latvia",
    116: "Lebanon",
    117: "Lesotho",
    118: "Liberia",
    119: "Libya",
    120: "Liechtenstein",
    121: "Lithuania",
    122: "Luxembourg",
    123: "Macao",
    124: "Macedonia",
    125: "Madagascar",
    126: "Malawi",
    127: "Malaysia",
    128: "Maldives",
    129: "Mali",
    130: "Malta",
    131: "Marshall Islands",
    132: "Martinique",
    133: "Mauritania",
    134: "Mauritius",
    135: "Mayotte",
    136: "Mexico",
    137: "Micronesia",
    138: "Moldova",
    139: "Monaco",
    140: "Mongolia",
    141: "Montserrat",
    142: "Morocco",
    143: "Mozambique",
    144: "Myanmar",
    145: "Namibia",
    146: "Nauru",
    147: "Nepal",
    148: "Netherlands",
    149: "Netherlands Antilles",
    150: "New Caledonia",
    151: "New Zealand",
    152: "Nicaragua",
    153: "Niger",
    154: "Nigeria",
    155: "Niue",
    156: "Norfolk Island",
    157: "Northern Mariana Islands",
    158: "Norway",
    159: "Oman",
    160: "Pakistan",
    161: "Palau",
    162: "Panama",
    163: "Papua New Guinea",
    164: "Paraguay",
    165: "Peru",
    166: "Philippines",
    167: "Pitcairn",
    168: "Poland",
    169: "Portugal",
    170: "Puerto Rico",
    171: "Qatar",
    172: "Reunion",
    173: "Romania",
    174: "Russian Federation",
    175: "Rwanda",
    176: "Saint Kitts and Nevis",
    177: "Saint Lucia",
    178: "Saint Vincent and the Grenadines",
    179: "Samoa",
    180: "San Marino",
    181: "Sao Tome and Principe",
    182: "Saudi Arabia",
    183: "Senegal",
    184: "Seychelles",
    185: "Sierra Leone",
    186: "Singapore",
    187: "Slovakia",
    188: "Slovenia",
    189: "Solomon Islands",
    190: "Somalia",
    191: "South Africa",
    192: "South Georgia and the South Sandwich Islands",
    193: "Spain",
    194: "Sri Lanka",
    195: "Saint Helena",
    196: "St Pierre and Miquelon",
    197: "Sudan",
    198: "Suriname",
    199: "Svalbard and Jan Mayen Islands",
    200: "Swaziland",
    201: "Sweden",
    202: "Switzerland",
    203: "Syrian Arab Republic",
    204: "Taiwan",
    205: "Tajikistan",
    206: "United Republic of Tanzania",
    207: "Thailand",
    208: "Togo",
    209: "Tokelau",
    210: "Tonga",
    211: "Trinidad and Tobago",
    212: "Tunisia",
    213: "Turkey",
    214: "Turkmenistan",
    215: "Turks and Caicos Islands",
    216: "Tuvalu",
    217: "Uganda",
    218: "Ukraine",
    219: "United Arab Emirates",
    220: "United Kingdom",
    221: "United States",
    222: "United States Minor Outlying Islands",
    223: "Uruguay",
    224: "Uzbekistan",
    225: "Vanuatu",
    226: "Vatican City",
    227: "Venezuela",
    228: "Vietnam",
    229: "Virgin Islands British",
    230: "Virgin Islands US",
    231: "Wallis and Futuna Islands",
    232: "Western Sahara",
    233: "Yemen",
    234: "Serbia",
    235: "Zambia",
    236: "Zimbabwe",
    237: "Aland Islands",
    238: "Bonaire Saint Eustatius and Saba",
    239: "Palestine",
    240: "Montenegro",
    241: "Guernsey",
    242: "Isle of Man",
    243: "Jersey",
    244: "Curaçao",
    245: "Ivory Coast",
    246: "Kosovo",
    247: "Sint Maarten",
    248: "Serbia and Montenegro",
    249: "Saint Barthelemy",
    250: "Democratic Republic of the Congo",
    251: "Saint Martin",
    252: "South Sudan"
}