export const creator = {
  REQUEST_OTP: () => {
    return `/User/RequestPhoneOtp`;
  },
  VERIFY_OTP: () => {
    return `/User/VerifyPhoneOtp`;
  },
  GET_CREATOR: () => {
    return `/Creator`;
  },
  GET_CREATOR_DATA: () => {
    return `/Creator/getCreatorInsights`;
  },
};
