import { useState, useEffect } from "react";
import { ReactComponent as BrandLogo } from "../Assets/svgs/LWTLogo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import StorageService from "../Services/storage.services";
import ApiService from "../Services/api.services";
import { resetCreatorData } from "../API";
import ContactSupportModal from "./ContactSupportModal";
import { ReactComponent as SettingsIcon } from "../Assets/svgs/setting.svg";

const HeaderComponent = ({noUserOptions, bgColor}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [toggleSupport, settoggleSupport] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      setisAuthenticated(true);
    }
  }, []);

  const userLogOut = () => {
    StorageService.clearAll();
    ApiService.removeHeader();
    navigate("/login");
    dispatch(resetCreatorData());
  };

  const settingsNavigate = () => {
    navigate("/settings");
  };

  return (
    <div className={`flex items-center justify-between w-full md:px-8 px-4 py-2 ${bgColor ? 'bg-['+bgColor+']' : 'shadow-md'}`} >
      <span
        onClick={() => navigate("/dashboard")}
        style={{ cursor: "pointer" }}
      >
        <BrandLogo />
      </span>
      <div
        className="flex md:flex-row flex-col-reverse 2xl:w-1/4 md:w-1/3 w-1/2 px-6 items-center"
        style={{
          justifyContent: isAuthenticated && window.location.pathname !== "/engage" && !noUserOptions ? "space-between" : "end",
        }}
      >
        <div className="text-sm text-gray-500">
          <button
            className="xl:ml-4 ml-0 mt-2 xl:mt-0"
            onClick={() => {
              if (isAuthenticated) {
                return settoggleSupport(true);
              }

              return window.location = 'mailto:contact@lookwhostalking.co.in'
            }}
          >
            Contact Support
          </button>
          {toggleSupport && (
            <ContactSupportModal settoggleSupport={settoggleSupport} />
          )}
        </div>
        {isAuthenticated && window.location.pathname !== "/engage" && !noUserOptions && (
          <div className="flex items-center">
            <button
              className="flex items-center outline-none border-none px-1.5 py-1 rounded-md align-self-end mr-6"
              onClick={() => {
                settingsNavigate();
              }}
            >
              <SettingsIcon className="mr-1" />
              Settings
            </button>
            <button
              className="outline-none border-none px-1.5 py-1 text-white rounded-md align-self-end"
              style={{ backgroundColor: "#f15847" }}
              onClick={() => {
                userLogOut();
              }}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
