import axios from "axios"
import constants from "../../constants"
import ApiService from "../api.services"

const AddInstagramManually = ({ platform, channelHandel }) => {
    return new Promise((resolve, reject) => {
        const payload = {
            platformType: platform,
            socialUsername: channelHandel
        }
        let token = localStorage.getItem("Authorization")

        let config = {
            headers: {
                Authorization: token
            },
        };
        return axios.post(constants.API_URL + '/Creator/addManualInstagramCreatorChannel', payload, config)
            .then((response) => {
                console.log("Response", response)
                return resolve(response.data)
            })
            .catch((error) => {
                return reject(error);
            })
    })
}

export default AddInstagramManually