function SupportLimiter() {
    return new Promise((resolve, reject) => {
        let OTPCount = localStorage.getItem("SupportCount") ? parseInt(localStorage.getItem("SupportCount")) : 0
        let LastOTPCallTime = localStorage.getItem("LSCT") ? localStorage.getItem("LSCT") : 0

        if (OTPCount >= 1 && ((new Date() - new Date(LastOTPCallTime)) > 5 * 60 * 1000)) {
            OTPCount = 0;
            LastOTPCallTime = 0

            localStorage.setItem("SupportCount", 0)
            localStorage.setItem("LSCT", 0)
        }

        if (OTPCount >= 3 && (new Date() - new Date(LastOTPCallTime) > 1 * 60 * 1000 / 2)) {
            return reject({
                status: false,
                message: "Too many frequent support requests. Please wait our support team will contact you soon"
            })
        }

        if (OTPCount < 3 && (new Date() - new Date(LastOTPCallTime) < 1 * 60 * 1000 / 2)) {
            return reject({
                status: false,
                message: `Please wait for ${Math.floor(30 - (new Date() - new Date(LastOTPCallTime)) / 1000)} secs.`
            })
        }

        if(OTPCount < 3 && (new Date() - new Date(LastOTPCallTime) > 1 * 60 * 1000 / 2)) {
            localStorage.setItem("SupportCount", OTPCount + 1)
            localStorage.setItem("LSCT", new Date())

            return resolve({
                status: true
            })
        } 
    })
}

export default SupportLimiter