import axios from "axios";
import constants from "../../constants";
import RegexStore from "../../Utils/RegexStore";
import ApiCallResponse from "./ApiCallResponse";

function submitMobileOtp({ otp = "", mobileNumber = "", callback = () => {} }) {
  return new Promise((resolve, reject) => {
    if (!Boolean(mobileNumber.match(RegexStore.MobileNumberRegex))) {
      return reject(
        new ApiCallResponse({
          status: true,
          sendReport: false,
          messageToUser: "Invalid Mobile Number",
          callback: callback,
        })
      );
    }

    if (Boolean(otp.length) && otp.length !== 4) {
      return reject(
        new ApiCallResponse({
          status: true,
          sendReport: false,
          messageToUser: "Invalid OTP",
        })
      );
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let form = new FormData();
    form.append("otp", otp);
    form.append("phoneNumber", `91${mobileNumber}`);

    return axios
      .post(constants.API_URL + "/User/VerifyPhoneOtp", form, config)
      .then((response) => {
        if (!response.data.status) {
          return reject(
            new ApiCallResponse({
              status: true,
              sendReport: false,
              messageToUser: response.data?.message,
            })
          );
        }

        return resolve(
          new ApiCallResponse({
            status: true,
            data: { token: response?.data?.data?.token },
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

export default submitMobileOtp;
