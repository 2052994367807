const SocialPlatformsEnum = {
    "Unassigned": 0,
    "Youtube": 1,
    "Twitter": 2,
    "Blog": 3,
    "Instagram": 4,
    "Facebook": 5,
    "Google": 6, 
    "LWT": 7,
    "Apple": 8,
    0: "Unassigned",
    1: "Youtube",
    2: "Twitter",
    3: "Blog",
    4: "Instagram",
    5: "Facebook",
    6: "Google",
    7: "LWT",
    8: "Apple"
}

export default SocialPlatformsEnum