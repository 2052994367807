const ContactSupportEnum = {
    "BasicSupport": 1,
    "RequestManager": 2,
    "CustomSubscriptionPlan": 3,
    "Invoicing": 4,
    1: "BasicSupport",
    2: "RequestManager",
    3: "CustomSubscriptionPlan",
    4: "Invoicing",
}

export default ContactSupportEnum