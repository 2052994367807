import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../Services/api.services";
import { creator } from "./Routes";

export const getOtpThunk = createAsyncThunk(
  "getotpthunk",
  async (mode, thunkApi) => {
    try {
      const [res, error] = await ApiService.post(creator.REQUEST_OTP(), mode);
      if (res) return res.data;
      if (error) return thunkApi.rejectWithValue(error.response.data);
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

export const getCreatorThunk = createAsyncThunk(
  "getcreatorthunk",
  async (callback, thunkApi) => {
    try {
      const [res, error] = await ApiService.get(creator.GET_CREATOR());
      if (res) return res.data;
      if (error) return thunkApi.rejectWithValue(error.response.data);
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

export const getCreatorDataThunk = createAsyncThunk(
  "getcreatordatathunk",
  async (callback, thunkApi) => {
    try {
      const [res, error] = await ApiService.get(
        creator.GET_CREATOR_DATA()
      );
      if (res) {
        if (typeof(callback) === 'function') {
          callback()
        }
        return res.data;
      }
      if (error) {
        if (typeof(callback) === 'function') {
          callback()
        }
        return thunkApi.rejectWithValue(error.response.data);
      }
    } catch (error) {
      thunkApi.rejectWithValue(error);
    }
  }
);

const getCreatorOtp = createSlice({
  name: "getotp",
  initialState: {
    isLoading: false,
    isSuccess: false,
    hasErrors: false,
    data: null,
  },
  reducers: {
    resetOtpRequest: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasErrors = false;
      state.data = null;

      return state;
    },
  },
  extraReducers: {
    [getOtpThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getOtpThunk.fulfilled]: (state, { payload }) => {
      //   console.log(payload, "otp here");
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getOtpThunk.rejected]: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = false;
      state.hasErrors = payload;
    },
  },
});

const getCreatorData = createSlice({
  name: "getData",
  initialState: {
    isLoading: false,
    isSuccess: false,
    hasErrors: false,
    data: null,
  },
  reducers: {
    resetCreatorData: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasErrors = false;
      state.data = null;

      return state;
    },
  },
  extraReducers: {
    [getCreatorThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorThunk.fulfilled]: (state, { payload }) => {
      // console.log(payload, "data here");
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getCreatorThunk.rejected]: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = false;
      state.hasErrors = payload;
    },
  },
});

const getCreatorDataInsights = createSlice({
  name: "creatordatainsights",
  initialState: {
    isLoading: false,
    isSuccess: false,
    hasErrors: false,
    data: null,
  },
  reducers: {
    resetCreatorDataInsights: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.hasErrors = false;
      state.data = null;

      return state;
    },
  },
  extraReducers: {
    [getCreatorDataThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getCreatorDataThunk.fulfilled]: (state, { payload }) => {
      // console.log(payload, "data here");
      state.isLoading = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getCreatorDataThunk.rejected]: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = false;
      state.hasErrors = payload;
    },
  },
});

export const { creatorOtp, resetOtpRequest } = getCreatorOtp.actions;
export const { creatorData, resetCreatorData } = getCreatorData.actions;
export const { creatorInsights, resetCreatorDataInsights } =
  getCreatorDataInsights.actions;

const creatorReducer = {
  creatorOtp: getCreatorOtp.reducer,
  creatorData: getCreatorData.reducer,
  creatorInsights: getCreatorDataInsights.reducer,
};
export default creatorReducer;
