import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ApiService from "./Services/api.services";
import StorageService from "./Services/storage.services";
import { Provider } from "react-redux";
import store from "./Redux/store";
import "react-tooltip/dist/react-tooltip.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

if(window.location.href.includes("/appFacebookConnect")) {
  const urlData = window.location.href.split('&')[1]
  window.history.replaceState(null, "User Connect Code", `/appFacebookConnect?data=${urlData}`)
}


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8HgpDO6fzQnr2VDDe6Hdot9st1j6JDMo",
  authDomain: "lwtindia-202710.firebaseapp.com",
  databaseURL: "https://lwtindia-202710.firebaseio.com",
  projectId: "lwtindia-202710",
  storageBucket: "lwtindia-202710.appspot.com",
  messagingSenderId: "1065280099001",
  appId: "1:1065280099001:web:939d81ae3f4ba5c4eac168",
  measurementId: "G-Y207DX0HEE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'webpageopened')

// const baseUrl = `https://lwtapitest.azurewebsites.net/api`;
const baseUrl =
  process.env.NODE_ENV === "development"
    ? `https://lwtapitest.azurewebsites.net/api`
    : `https://lwtapiprod.azurewebsites.net/api`;

    if (process.env.NODE_ENV === 'production') {
      console.log = () => {}
      console.error = () => {}
      console.debug = () => {}
    }

ApiService.init(baseUrl);

ApiService.setHeader();
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
      <Suspense fallback={<div>Loading...</div>} />

        <App />
      </Router>
    </Provider>
  </React.StrictMode>
);
