import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderComponent from "../components/header";
import Interval from "../Utils/intervals";

function Loader() {
  const Navigate = useNavigate();

  useEffect(() => {
    let isNotClosingAutomaticallyCheck = Interval.make(() => {
      console.log("THISS SSSS", Interval.intervals.size);
      let isIntervalsThere = Interval.intervals.size === 1;
      if (isIntervalsThere) {
        Navigate("/login");
      }
    }, 2000);

    return () => Interval.clear(isNotClosingAutomaticallyCheck);
  }, []);

  return (
    <div>
      <HeaderComponent />
      <div
        className="flex flex-col px-8 pt-8 w-full"
        style={{
          background: "#e2e2e2",
          height: "calc(100vh - 91px)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <span>This window will close automatically. </span>
        <span
          onClick={() => window.close()}
          style={{
            color: "blue",
            cursor: "pointer",
            paddingLeft: "0.5em",
            paddingRight: "0.5em",
          }}
        >
          {" "}
          {` Press here `}
        </span>{" "}
        <span>if it takes more than 10 secs.</span>
      </div>
    </div>
  );
}

export default Loader;
