export const PostType = {
  Unassigned: 0,
  Image: 1,
  Video: 2,
  Document: 3,
  Text: 4,
  Audio: 5,
  0: "Unassigned",
  1: "Image",
  2: "Video",
  3: "Document",
  4: "Text",
  5: "Audio",
};

export const ContentType = {
  Unassigned: 0,
  Post: 1,
  Carousel: 2,
  Story: 3,
  Text: 4,
  URL: 5,
  Document: 6,
  IGTV: 7,
  Reel: 8,
  Shorts: 9,
  0: "Unassigned",
  1: "Post",
  2: "Carousel",
  3: "Story",
  4: "Text",
  5: "URL",
  6: "Document",
  7: "IGTV",
  8: "Reel",
  9: "Shorts",
};
