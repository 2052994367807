import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
// import Login from "./Screens/Login";
// import Dashboard from "./Screens/Dashboard";
// import PrivateRoutes from "./Routes/PrivateRoutes";
import Loader from "./Screens/Loader";
import Settings from "./Screens/Settings";
// import MobileDeviceDetected from "./Screens/MobileDeviceDetected";
import { useDispatch } from "react-redux";
import { getCreatorThunk } from "./API";
import { ReactComponent as BrandLogo } from "./Assets/svgs/logo.svg";
import LoaderIcon from "./Assets/gifs/lwtLoader.gif";
import DLhandler from "./Screens/dlHandler";
import Recruitment from "./Screens/Recruitment";

import { Toaster } from "react-hot-toast";

const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ "./Screens/Login")
);
const PrivateRoutes = lazy(() =>
  import(/* webpackChunkName: "PrivateRoutes" */ "./Routes/PrivateRoutes")
);
const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./Screens/Dashboard")
);
const MobileDeviceDetected = lazy(() =>
  import(
    /* webpackChunkName: "MobileDeviceDetected" */ "./Screens/MobileDeviceDetected"
  )
);
const AppFacebookConnect = lazy(() =>
  import(
    /* webpackChunkName: "AppFacebookConnect" */ "./Screens/FacebookChannelConnect"
  )
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    let isAuthorized = localStorage.getItem("Authorization");

    if (isAuthorized) {
      dispatch(getCreatorThunk());
    }
  }, []);

  return (
    <Suspense
      fallback={
        <div className="flex flex-col w-full h-full justify-center items-center">
          <BrandLogo />
          <div className="flex items-center mt-8 text-2xl font-semibold">
            Loading <img className="h-8 ml-4" src={LoaderIcon} alt="Loading" />
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/login" Component={Login} />
        <Route path="/mobileDetected" Component={MobileDeviceDetected} />
        <Route path="/appFacebookConnect" Component={AppFacebookConnect} />
        <Route path="/dl/*" Component={DLhandler} />
        <Route path="/engage/*" Component={Recruitment} />
        {/* Internal navigation routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" Component={Dashboard} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/*" element={<Navigate replace to="/login" />} />

        {/** */}
      </Routes>

      <Toaster
        containerStyle={{
          zIndex: 999999999999,
        }}
        toastOptions={{
          style: {
            zIndex: 999999999999,
          },
        }}
      />
    </Suspense>
  );
}

export default App;
