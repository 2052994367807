import axios from "axios";

export const GetConnectedInstagramAccountFromAccessTokens = (accessToken) => {

    return new Promise((resolve, reject) => {

        axios.get(`https://graph.facebook.com/v19.0/me/accounts?access_token=${accessToken}&fields=connected_instagram_account{name,username},name,username,access_token`)
            .then((response) => {
                return resolve(response.data.data);
            })
            .catch((error) => {
                console.log("error file instagram graph request", error);
                return reject(error);
            })
    })
}