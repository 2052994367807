import axios from "axios";
import { GetConnectedInstagramAccountFromAccessTokens } from "./GetConnectedInstagramAccountFromAccessToken";

function CheckUserFromToken(token) {
    return new Promise((resolve, reject) => {
        return axios.get(`https://graph.facebook.com/v20.0/me/permissions?access_token=${token}`)
            .then(data => {
                console.log("datatatatatata", data)
                const DeclinedPermissions = data?.data?.data.filter((permission) => {
                    return permission.status === "declined"
                })

                if (DeclinedPermissions.length > 0) {
                    const DecPerArray = DeclinedPermissions.map(item => item?.permission)
                    return reject({ message: "PermissionIssue", details: DecPerArray.join(" ") })
                }
                return GetConnectedInstagramAccountFromAccessTokens(token)
            })
            .then((data) => {
                return resolve({ accounts: data, accessToken: token })
            })
            .catch(error => {
                console.log("erooroorororororor", error);
                if (
                    error?.message?.toLowerCase()?.includes('network') ||
                    error?.message?.toLowerCase()?.includes('host')
                ) {
                    return reject("no error")
                } else if (error?.message === 'Permissions not granted') {
                    return reject({ message: "PermissionIssue", details: error.data.join(" ") })
                } else if (error?.message === "Login Cancelled, try again") {
                    return reject(error)
                } else {
                }

                return reject({ status: false, error: "Handled Instagram Issue" })
            });
    })
}

export default CheckUserFromToken