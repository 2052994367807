const ChannelConnectType = {
    Unassigned : 0,
    YoutubeStandard : 1,
    YoutubeBrand : 2,
    YoutubeLink : 3,
    InstagramProfessional : 4,
    InstagramBasic : 5,
    InstagramLink : 6,
    TwitterStandard : 7,
    TwitterLink : 8,
    0: "Unassigned",
    1: "YoutubeStandard",
    2: "YoutubeBrand",
    3: "YoutubeLink",
    4: "InstagramProfessional",
    5: "InstagramBasic",
    6: "InstagramLink",
    7: "TwitterStandard",
    8: "TwitterLink"
}

export default ChannelConnectType
