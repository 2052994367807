/**
 * 
 * @param {Object} billingDetails 
 * @returns {Promise} -> it will reject if any of the cases are false and that object will have a message
 * field to which can be directly mapped for the user's alert box.
 */
export default function billingDetailsValidateFields(billingDetails) {
    return new Promise((resolve, reject) => {

        // check for all the mandatory fields are filled
        if (
            !(Boolean(billingDetails.name) || Boolean(billingDetails.address) ||
            Boolean(billingDetails.phonenumber) || Boolean(billingDetails.city) ||
            Boolean(billingDetails.state) ||
            Boolean(billingDetails.pincode) || Boolean(billingDetails.aadhar) ||
            Boolean(billingDetails.accountholder) || Boolean(billingDetails.bankname) ||
            Boolean(billingDetails.accountnumber) || Boolean(billingDetails.ifsc) ||
            Boolean(billingDetails.upi) || Boolean(billingDetails.country)|| Boolean(billingDetails.pan) )
          ) {
            return reject({status: false, message: "Please fill all the mandatory fields"})
          }

        // check for phone number's format
        if (billingDetails.phonenumber.length < 9 ||
            billingDetails.phonenumber.length > 12) {
            return reject({status: false, message: "Please enter a valid phone number."})
        }

        // check for aadhar number's format
        if (!/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/.test(billingDetails.aadhar)) {
            return reject({status: false, message: "Enter a valid Aadhar Number"})
        }

        // check for UIP id's format
        if (!/^[\w.-]+@[\w.-]+$/.test(billingDetails.upi)) {
            return reject({status: false, message: "Enter a valid UPI"})
        }

        // check for account number's format
        if (billingDetails.accountnumber.length < 9 ||
            billingDetails.accountnumber.length > 18) {
            return reject({status: false, message: "Enter a valid bank account number"})
        }

        // check for ifsc code's format
        if (billingDetails.ifsc.length !== 11) {
            return reject({status: false, message: "Enter a valid IFSC Code"})
        }
        
        let panRegex = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}")
        let isPanCorrect = panRegex.test(billingDetails.pan)

        // check for pan's format
        if (!isPanCorrect) {
            return reject({status: false, message: "Enter a valid PAN"})
        }

        return resolve({status: true})

    })
  };