function OTPLimiter() {
    return new Promise((resolve, reject) => {
        let OTPCount = localStorage.getItem("OTPCount") ? parseInt(localStorage.getItem("OTPCount")) : 0
        let LastOTPCallTime = localStorage.getItem("LOCT") ? localStorage.getItem("LOCT") : 0

        if (OTPCount >= 1 && ((new Date() - new Date(LastOTPCallTime)) > 5 * 60 * 1000)) {
            OTPCount = 0;
            LastOTPCallTime = 0

            localStorage.setItem("OTPCount", 0)
            localStorage.setItem("LOCT", 0)
        }

        if (OTPCount >= 3 && (new Date() - new Date(LastOTPCallTime) > 1 * 60 * 1000 / 2)) {
            return reject({
                status: false,
                message: "You are out of OTP limit. Please contact support"
            })
        }

        if (OTPCount < 3 && (new Date() - new Date(LastOTPCallTime) < 1 * 60 * 1000 / 2)) {
            return reject({
                status: false,
                message: `Please wait for ${Math.floor(30 - (new Date() - new Date(LastOTPCallTime)) / 1000)} secs.`
            })
        }

        if(OTPCount < 3 && (new Date() - new Date(LastOTPCallTime) > 1 * 60 * 1000 / 2)) {
            localStorage.setItem("OTPCount", OTPCount + 1)
            localStorage.setItem("LOCT", new Date())

            return resolve({
                status: true
            })
        } 
    })
}

export default OTPLimiter