import axios from "axios";
import constants from "../../constants";

const AcceptCampaign = (campaignId, creatorId, channels, signLink) => {
    console.log("acceppptttteedddd camoaugnsndnf");
    return new Promise((resolve, reject) => {
        // set store campaign state to engage

        let requestBody = {
            campaignId,
            creatorId,
            channels,
            signLink,
        };

        let token = localStorage.getItem("Authorization")

        let config = {
            headers: {
                Authorization: token
            },
        };

        return axios.post(`${constants.API_URL}/Bridge/${campaignId}/acceptCampaign`, requestBody, config)
            .then(response => {
                console.log("mapresponse", response);
                return resolve(response);
            })
            .catch(error => {
                console.log(error);
                return reject(error);
            });
    });
};

export default AcceptCampaign;