class ApiCallResponse {
    constructor({status = true, data= {}, errorObject={}, isFatal=false, messageToUser='', sendReport=false, callback=()=>{}}) {
        this.status = status
        this.data = data
        this.errorObject = errorObject
        this.isFatal = isFatal
        this.messageToUser = messageToUser
        this.sendReport = sendReport
        this.callback = callback
    }

    getResponse() {
        if (this.status) {
            return {
                data: this.data,
                status: this.status
            }
        }

        return {
            errorObject: this.errorObject,
            status: this.status,
            isFatal: this.isFatal,
            messageToUser: this.messageToUser,
            sendReport: this.sendReport
        }
    }

    isSuccess() {
        return this.status
    }

    shouldReport() {
        return this.sendReport
    }

    getErrorMessageToDisplay() {
        return this.messageToUser
    }

    getCallback() {
        return this.callback()
    }
    
}

export default ApiCallResponse