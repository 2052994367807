const constants = {
    API_URL: process.env.NODE_ENV === 'development' ? `https://lwtapitest.azurewebsites.net/api` : `https://lwtapiprod.azurewebsites.net/api`,
    IS_ENV_DEV: process.env.NODE_ENV === 'development',
    isDev: false,
    facebook: {
        convertToProfessionalAndConnectURI: process.env.NODE_ENV === 'development' ? `https://www.facebook.com/dialog/oauth?client_id=718557455009852&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=https://localhost:3000/loader/&response_type=token&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management` : `https://www.facebook.com/dialog/oauth?client_id=718557455009852&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=https://creator.lookwhostalking.co.in/loader/&response_type=token&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management`,
        connectPersonalAccountURI: process.env.NODE_ENV === 'development' ? "https://api.instagram.com/oauth/authorize?client_id=126842749325769&redirect_uri=https://localhost:3000/loader/&scope=user_profile,user_media&response_type=code" : "https://api.instagram.com/oauth/authorize?client_id=126842749325769&redirect_uri=https://creator.lookwhostalking.co.in/loader/&scope=user_profile,user_media&response_type=code",
    },
    instagram: {
        accountConnectURI: process.env.NODE_ENV === 'development' ? `https://www.facebook.com/v20.0/dialog/oauth?client_id=718557455009852&redirect_uri=https://localhost:3000/appFacebookConnect&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management` : `https://www.facebook.com/v20.0/dialog/oauth?client_id=718557455009852&redirect_uri=https://creator.lookwhostalking.co.in/appFacebookConnect&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management`
    },
    youtube: {
        brandLink: process.env.NODE_ENV === 'development' ? "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://localhost:3000/loader&prompt=consent&response_type=code&client_id=1065280099001-e0gu1tilv5nhlrshvlb4v6f91egkqh0e.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/youtube.readonly+https://www.googleapis.com/auth/yt-analytics.readonly&access_type=offline" : "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://creator.lookwhostalking.co.in/loader&prompt=consent&response_type=code&client_id=1065280099001-e0gu1tilv5nhlrshvlb4v6f91egkqh0e.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/youtube.readonly+https://www.googleapis.com/auth/yt-analytics.readonly&access_type=offline"
    }
}

export default constants;
