const OperatingSystemEnum = {
    "ios": 0,
    "android": 1,
    "desktop": 2,
    "windows_phone": 3,
    0: "ios",
    1: "android",
    2: "desktop",
    3: "windows_phone"
}

export default OperatingSystemEnum