import DLActionEnum from "../Enums/DLActionEnums"
import OperatingSystemEnum from "../Enums/OperatingSystemEnum";
import getMobileOperatingSystem from "./getMobileOperatingSystem";

function DLActionHandler (link) {
    const queryParameters = new URLSearchParams(link)
    const action = queryParameters.get("action")
    
    switch (action) {
        case DLActionEnum.referrer:
            const RefSourceId = queryParameters.get("RefSourceId")
            const DeviceType = getMobileOperatingSystem()
            if(DeviceType === OperatingSystemEnum.android) {
                return window.open(`market://details?id=com.lookwhostalkingApp&referrer=${RefSourceId}`)
            }
            
            if (DeviceType === OperatingSystemEnum.ios) {
                return window.open("itms-apps://itunes.apple.com/app/id1579637316")
            }
            break;

        case DLActionEnum.campaign_share: 
            
            break;
    
        default:
            break;
    }
}

export default DLActionHandler