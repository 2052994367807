const CampaignCreatorStatusEnum = {
    'Undefined': 0,
    'invited': 1,
    'connected': 2,
    'bidding': 3,
    'content': 4,
    'live': 5,
    'invoicing': 6,
    'end': 7,
    'rejected': 8,
    'withdrawn': 9,
    'paused': 10,
    'abandoned': 11,
    0: 'Undefined',
    1: 'invited',
    2: 'connected',
    3: 'bidding',
    4: 'content',
    5: 'live',
    6: 'invoicing',
    7: 'end',
    8: 'rejected',
    9: 'withdrawn',
    10: 'paused',
    11: 'abandoned',
  };
  
  export default CampaignCreatorStatusEnum