const ACCESS_TOKEN = () => {
  return localStorage.getItem("Authorization");
};

const StorageService = {
  clearAll() {
    localStorage.clear();
  },

  saveUserData() {
    localStorage.setItem();
  },

  getUser() {
    localStorage.getItem();
  },

  removeUser() {
    localStorage.removeItem();
  },

  saveAccessToken(token) {
    localStorage.setItem("token", token);
  },

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN());
  },

  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  },
};

export default StorageService;
