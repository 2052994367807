import axios from "axios";
import StorageService from "./storage.services";

const errorHandler = async (promise) => {
  try {
    const res = await promise;
    return [res, null];
  } catch (error) {
    //console.error("here", error.response.status);
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.pathname = "/login";
    }
    return [null, error];
  }
};

const ApiService = {
  init: (baseUrl) => {
    axios.defaults.baseURL = baseUrl;
  },

  setHeader: () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "Authorization"
    )}`;
    // console.log("Header Set");
  },

  removeHeader: () => {
    axios.defaults.headers.common = {};
    console.log("Header Removed");
  },

  get: async (url, config) => {
    return errorHandler(axios.get(url, config));
  },

  post: async (url, data, config) => {
    return errorHandler(axios.post(url, data, config));
  },

  put: async (url, data, config) => {
    return errorHandler(axios.put(url, data, config));
  },

  patch: async (url, data, config) => {
    return errorHandler(axios.patch(url, data, config));
  },

  delete: async (url, config) => {
    return errorHandler(axios.delete(url, config));
  },
};

export default ApiService;
