function getProfessionalInstagramAccount(accounts) {
    // const demo = [
    //     {
    //         "socialId": "17841400647068599",
    //         "socialUsername": "4.squared_",
    //         "instagramAccountId": "109882138546164",
    //         "page_access_token": "EAAKNhjHbSDwBO4rZB6gKFjJfiecsV9Tf0WbCEDDwg5e5xaEr1c42d1u4oXMZBeAdd8WltbzssnUA7JFd2GZBZBQ2UeDLJ1zOhkLxsTCxJHty5ZCrmgbjv31qZB9FUmGwpdFE1rwiq4dJ7sSDTiPsnPjZAjHibvOm1JnsqWy6X6BkAu4zsTxR9yUnZBTUrkTQrV0ZD"
    //     },
    //     null,
    //     {
    //         "socialId": "17841411633492203",
    //         "socialUsername": "bios2k19",
    //         "instagramAccountId": "626694731122564",
    //         "page_access_token": "EAAKNhjHbSDwBO9e6h9Jj13Pdn2nZCPe8bpYoWGo1wXbZB0mC37fzuA6Gd2cTbfvM0GVGuWI15UNgWoYeCXUXJvJ1FvRZA0DpfV7I5wJdZCMLPflSRONhWIweB1Q3z9Q6pvnqr5dLYyjgdxZB5s2VYHcr6AMCZAZAoSE61cxDwsIldscR0NLlpWTbZArDYs6YoDQU"
    //     },
    //     {
    //         "socialId": "17841408296820208",
    //         "socialUsername": "geekisthan",
    //         "instagramAccountId": "1901237153528802",
    //         "page_access_token": "EAAKNhjHbSDwBO5aS8bXYiVkXF1CCabrOIm22tKIf6D0mFRCDaB6HvaGQE2Tp6J0XnXLKyNvoOoCxfSrvc9QZA8VE8z26iDkD0rWwIeKtd1TQtnB6RdZAWLm3JV2goc13YQe8Vv1q5YIx0oaiZAIYCfe0hoMsMDkyBVGkRT2UodsMvUV5qZC0SZBH9WC07OKMZA"
    //     },
    //     null
    // ]
    // return demo

    // return true
    let instagramAccounts = accounts.map(instaAccount => {
        if (!instaAccount.hasOwnProperty('connected_instagram_account')) {
            return
        }

        return ({
            socialId: instaAccount.connected_instagram_account.id,
            socialUsername: instaAccount.connected_instagram_account.username,
            instagramAccountId: instaAccount.id,
            page_access_token: instaAccount.access_token
        })
    })

    return instagramAccounts
}

export default getProfessionalInstagramAccount