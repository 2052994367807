import axios from "axios";
import constants from "../../constants";

function GetInstagramTokenFromCode(code) {

    const Form = new FormData()
    Form.append("token", code)

    return new Promise((resolve, reject) => {
        return axios.post(`${constants.API_URL}/Bridge/getIgAccessTokenFromAuthCode`, Form, {
            header: { 'Content-Type': 'multipart/form-data' }
        })
        .then(data => {
            console.log("instagram token", data);
            if (data?.data?.status) {
                return resolve(data?.data?.data?.token)
            }

            return reject("Error while getting token")
        })
        .catch(err => {
            console.error("instagram token error", err);
            return reject("Error while getting token")
        }) 
    })

    
}

export default GetInstagramTokenFromCode