import React, { useState } from "react";
import { ReactComponent as Close } from "../Assets/svgs/close.svg";
import cogoToast from "cogo-toast";
import { getContactSupport } from "../Services/apicalls/ContactSupport";
import SupportLimiter from "../Utils/SupportLimiter";
import Loader from "./Loader";
import ContactSupportEnum from "../Enums/ContactSupportEnum";

const ContactSupportModal = ({ settoggleSupport }) => {
  const [query, setquery] = useState("");
  const [alreadyOpen, setalreadyOpen] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);

  var timeStamp = localStorage.getItem("ContactSupportLwt");

  const handleAlreadyOpen = () => {
    setalreadyOpen(true);
  };
  const handleAlreadyClose = () => {
    setalreadyOpen(false);
  };

  const handleContactSupport = () => {
    if (ShowLoader) {
      return;
    }
    setShowLoader(true);
    SupportLimiter()
      .then(() =>
        getContactSupport(
          ContactSupportEnum.BasicSupport,
          JSON.stringify({ toolType: "Web App Creator", message: query })
        )
      )
      .then((resolve) => {
        if (resolve.status === true) {
          cogoToast.success("Support request successfully sent.");
          setTimeout(
            () => cogoToast.success("Our Support Team will you contact soon."),
            2000
          );

          if (timeStamp === undefined || timeStamp === null) {
            handleAlreadyClose();

            localStorage.setItem("ContactSupportLwt", Date.now());
          } else {
            handleAlreadyClose();
          }
          settoggleSupport(false);
        } else {
          cogoToast.error(
            "Oops! Something went wrong, Please try again later.",
            {
              position: "top-center",
              hideAfter: 2,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error(err.message);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <div>
      {" "}
      <div className="flex flex-col items-center justify-center fixed top-1/4 xl:left-1/3 left-4 px-4 py-6 xl:w-1/3 w-11/12 bg-white mt-2 rounded-xl drop-shadow-lg z-50 h-max">
        {ShowLoader ? <Loader /> : <></>}
        <div className="flex w-full justify-end">
          <Close
            className="h-4 -mt-4 -mr-2 cursor-pointer"
            onClick={() => settoggleSupport(false)}
          />
        </div>
        <div>Please, let us know about your queries.</div>
        <div className="flex w-2/3 items-center my-4">
          <textarea
            value={query}
            className="w-full outline-none border border-gray-400 p-2 rounded-md text-sm"
            style={{ resize: "none" }}
            rows={4}
            cols={8}
            onChange={(e) => {
              setquery(e.target.value);
            }}
          />
        </div>
        <div className="flex 2xl:w-1/3 w-1/2 justify-between items-center px-4">
          <button
            className="flex items-center justify-center w-max-content px-3 py-1 text-white drop-shadow rounded-lg cursor-pointer"
            style={{
              background: "#f15847",
              cursor: query.length > 2 ? "pointer" : "not-allowed",
            }}
            disabled={query.length > 2 ? false : true}
            onClick={handleContactSupport}
          >
            Contact
          </button>
          <button onClick={() => settoggleSupport(false)}>Dismiss</button>
        </div>
      </div>
      <div
        className="fixed h-screen bg-black opacity-20 w-full top-0 left-0 z-10"
        onClick={() => settoggleSupport(false)}
      ></div>
    </div>
  );
};

export default ContactSupportModal;
